import React, { useEffect, useState } from "react";
import axios from "axios";
import "./contact.css"; // Import your custom CSS for styling
import Rightcontainer from "../Components/Rightcontainer";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  let baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setWebsiteSettings(data);
  };

  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("deposit");
  const [response, setResponse] = useState("");
  const [image, setImage] = useState(null);
  const [newContacts, setNewContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchNewContacts = async () => {
      try {
        const access_token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${access_token}`,
        };
        const response = await axios.get(
          baseUrl + `api/contacts/new?page=${currentPage}`,
          { headers }
        );
        setNewContacts(response.data.contacts);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching new contacts:", error);
      }
    };

    fetchNewContacts();
  }, [currentPage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("message", message);
      formData.append("category", category);
      if (image) {
        formData.append("image", image); // Append image file to FormData if it exists
      }

      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data", // Ensure proper headers for FormData
      };

      const res = await axios.post(baseUrl + "api/contact", formData, {
        headers,
      });
      setResponse("Ticket created successfully!");
      // Reset form fields after successful submission
      setMessage("");
      setCategory("deposit");
      setImage(null);
      // Refetch new contacts after submission to update list
      //  fetchNewContacts();
    } catch (error) {
      console.error("Error creating ticket:", error);
      setResponse("Error creating ticket");
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected image file to state
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="Support">
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="cxy flex-column" style={{ paddingTop: "16%" }}>
          <div className="ContactPage">
            <h3>Ticket Form</h3>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Message:</label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <div>
                <label>Category:</label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  <option value="deposit">Deposit</option>
                  <option value="withdrawal">Withdrawal</option>
                  <option value="Fraud Player">Fraud Player</option>
                  <option value="Game Clear">Game Clear</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div>
                <label>Attach Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
              <button type="submit">Create</button>
            </form>
            <p>{response}</p>
            <div>
            <hr/>
            <center>
            <h3>Contact us on</h3>
<div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", marginBottom: "5px" }}>
  <a className="" target="_blank" href="https://instagram.com/superludos">
    <img
      src="/Images/insta.png"
      style={{ height: "50px", width: "50px" }}
    />
  </a>
  <a href={"https://t.me/superludos"}>
    <img
      src="/Images/telegram.png"
      style={{ height: "50px", width: "50px" }}
    />
  </a>
</div>
</center>
            </div>
          </div>
          {newContacts.length > 0 && (
            <div className="new-contacts-section">
              <h1>All Tickets</h1>
              <ul>
                {newContacts.map((contact) => (
                  <li key={contact._id}>
                    <p>Message: {contact.message}</p>
                    <p>Category: {contact.category}</p>
                    <p>Status: {contact.status}</p>
                    <p>
                      Created At: {new Date(contact.createdAt).toLocaleString()}
                    </p>
                  </li>
                ))}
              </ul>
              {/* Pagination Controls */}
              <div className="pagination">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span>
                  {currentPage} / {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support;
