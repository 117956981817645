import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import Swal from "sweetalert2";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [open, setOpen] = useState(false);
  const [ludoking, setLudoking] = useState(false);
  const [webludo, setWebludo] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [accessToken, setAccessToken] = useState("");
  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");
      const data = await response.json();
      setLudoking(data.ludoking);
      setWebludo(data.classiclite);
      setWebsiteSettings(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("m") === "s") {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The transaction was successful!",
      });
    } else if (params.get("m") === "f") {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "The transaction failed. Please try again.",
      });
    }
  }, [location]);

  const role = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get(baseUrl + "me", { headers });
        setUserAllData(response.data);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem("token");
        }
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setAccessToken(token);
    if (token) {
      role();
    }
    fetchData();
  }, []);
  const collapseCardStyle = {
    border: "1px solid #ddd",
    borderRadius: "2px",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.1)",
    margin: "2px",
    overflow: "hidden",
    backgroundColor: "#D2122E",
  };

  const collapseCardHeaderStyle = {
    backgroundColor: "#f7f7f7",
    fontSize: "1.25rem",
    fontWeight: "bold",
    borderBottom: "0px solid #ddd",
  };

  const collapseCardBodyStyle = {
    padding: "8px",
  };

  const collapseCardTextStyle = {
    color: "#fff",
    fontWeight: "bold",
    lineHeight: "1.5",
    margin: "0",
  };
  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "50px" }}>
          <div className="collapseCard-container">
            <div style={collapseCardStyle}>
              <div style={collapseCardHeaderStyle}></div>
              <div style={collapseCardBodyStyle}>
                <p style={collapseCardTextStyle}>
                  {WebSitesettings.websitemsg}
                </p>
              </div>
            </div>
          </div>
          <section
            style={{
              position: "fixed",
              bottom: "10px",
              zIndex: "999",
              margin: "10px",
              right: "5px",
            }}
          >
            <div style={{ marginBottom: "5px" }}>
              <a
                className=""
                target="_blank"
                href="https://chat.whatsapp.com/EK7DOgASMSj71gMLTwmsZi"
              >
                <img
                  src="/Images/whatsapp.png"
                  style={{ height: "50px", width: "50px" }}
                />
              </a>
            </div>
            <div>
              <a href={"https://t.me/superludo_offical"}>
                <img
                  src="/Images/telegram.png"
                  style={{ height: "50px", width: "50px" }}
                />
              </a>
            </div>
          </section>

          <section className="games-section p-3">
            <div
              className="align-items-center games-section-title"
              style={{ textAlign: "center", position: "relative" }}
            >
              <h4> Our Tournaments</h4>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                }}
              >
                <Link to={"Spinearn"}>
                  <img
                    src="/Images/casino.png"
                    style={{
                      width: "50px",
                      height: "50px",
                      animation: "bounce 2.0s ease-in-out infinite",
                    }}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="games-section-headline mt-5 mb-1">
              <div className="games-window">
                {webludo && (
                  <>
                    <Link
                      className="gameCard-container"
                      to={
                        accessToken !== "" && accessToken !== null
                          ? "/Homepage/classicpro"
                          : "/login"
                      }
                    >
                      <p style={{ textAlign: "center" }}>₹50-₹50K</p>
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src={process.env.PUBLIC_URL + "/banner/banner4.jpeg"}
                          alt=""
                        />
                      </picture>
                    </Link>

                    <Link className="gameCard-container" to={`/#`}>
                      <p style={{ textAlign: "center" }}>Coming Soon</p>
                      <picture
                        className="gameCard-image"
                        style={{
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          border: "2px solid #000",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                          }}
                          src={
                            process.env.PUBLIC_URL + "/Images/LudoPopular.webp"
                          }
                          alt=""
                        />
                      </picture>
                    </Link>
                  </>
                )}
                {ludoking && (
                  <>
                    <Link
                      className="gameCard-container"
                      to={
                        accessToken !== "" && accessToken !== null
                          ? "/Homepage/ludoking"
                          : "/login"
                      }
                    >
                      <p style={{ textAlign: "center" }}>₹50-₹50k</p>
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src={process.env.PUBLIC_URL + "/banner/banner4.jpeg"}
                          alt=""
                        />
                      </picture>
                    </Link>

                    <Link className="gameCard-container" to={`#`}>
                      <p style={{ textAlign: "center" }}>Coming Soon</p>
                      <picture
                        className="gameCard-image"
                        style={{
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          border: "2px solid #000",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                          }}
                          src={
                            process.env.PUBLIC_URL + "/Images/LudoPopular.webp"
                          }
                          alt=""
                        />
                      </picture>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </section>
          <section className="footer">
            <div className="footer-divider" />
            <center>
              <Link to="/support">
                <div
                  style={{
                    backgroundColor: "red",
                    width: "90%",
                    height: "35px",
                    padding: "5px",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textDecoration: "none", color: "#fff" }}>
                    Contact us / Support
                  </p>
                </div>
              </Link>
            </center>
          </section>
          <section className="footer">
            <div className="footer-divider" />
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src="/Images/LandingPage_img/Header_profile.jpg"
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "56px", height: "56px" }}
                />
              </picture>
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
                className={!open ? "d-block" : "d-none"}
              >
                Terms, Privacy, Support
              </span>

              {open ? (
                <i
                  className="mdi mdi-chevron-up ml-auto"
                  style={{
                    fontSize: "12px",
                    color: "rgb(103, 103, 103)",
                    textAlign: "center",
                  }}
                ></i>
              ) : (
                <i
                  style={{
                    fontSize: "12px",
                    color: "#000",
                    textAlign: "center",
                  }}
                  className="mdi mdi-chevron-down ml-auto"
                ></i>
              )}
            </a>
            <Collapse in={open}>
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <h5 style={{ color: "#000" }}>Our Business & Products</h5>
                  <p style={{ color: "#000" }}>
                    We are an HTML5 game-publishing company and our mission is
                    to make accessing games fast and easy by removing the
                    friction of app-installs. superludo club is a skill-based
                    real-money gaming platform accessible only for our users in
                    India. It is accessible on https://www.superludo.club. On
                    superludo.club, users can compete for real cash in
                    Tournaments and Battles. They can encash their winnings via
                    popular options such as Paytm Wallet, Amazon Pay, Bank
                    Transfer, Mobile Recharges etc.
                  </p>
                </div>
              </div>
            </Collapse>
            <div className="footer-divider" />
            <div className="px-3 py-4"></div>
          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
    </>
  );
}
