import { Wheel } from "react-custom-roulette";
import React, { useEffect, useState } from "react";
import "./YourComponent.css";
import Rightcontainer from "../Components/Rightcontainer";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";

const Spinearn = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const wssurl = process.env.REACT_APP_SOCKETURL;

  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const notify = (winner) =>
    toast.success(`🦄 You have won ${winner}💵`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const betterLuck = (msg) =>
    toast.warn(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const data = [
    { option: "0" },
    { option: "1" },
    { option: "2" },
    { option: "5" },
    { option: "10" },
    { option: "20" },
    { option: "50" },
    { option: "100" },
    { option: "500" },
  ];
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [spinStatus, setSpinStatus] = useState([]);

  const handleSpinClick = () => {
    console.log(spinStatus);
    if (spinStatus.status) {
      betterLuck(spinStatus.msg);
    } else {
      const newPrizeNumber = spinStatus.spinEarn;
      // Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };
    
  const winnerss = [
    { name: "Taufik", prize: 50, time: "An Hour Ago" },
    { name: "Irshad", prize: 50, time: "2 Hours Ago" },
    { name: "Hansraj Singh Rathore", prize: 50, time: "2 Hours Ago" },
    { name: "Mohd. Faisal", prize: 50, time: "2 Hours Ago" },
    { name: "Warish", prize: 50, time: "3 Hours Ago" },
    { name: "Rafik", prize: 50, time: "3 Hours Ago" },
    { name: "Vikram Singh", prize: 50, time: "3 Hours Ago" },
    { name: "Sageer", prize: 50, time: "3 Hours Ago" },
    { name: "Karan Singh", prize: 50, time: "3 Hours Ago" },
    { name: "Talim", prize: 50, time: "4 Hours Ago" },
    { name: "Rahul Jangir", prize: 50, time: "4 Hours Ago" },
    { name: "Momin", prize: 50, time: "4 Hours Ago" },
    { name: "Vinod Kumari", prize: 50, time: "4 Hours Ago" },
  ];

  const [winners, setWinners] = useState([]);
  const [coin, setCoin] = useState("0");
  const [isDisableDaily, setIsDisableDaily] = useState(false);
  const [isDisableCost, setIsDisableCost] = useState(false);
  const [spintype, setspintype] = useState(null);
  const access_token = localStorage.getItem("token");

  const getSpin = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (access_token) {
      axios
        .get(baseUrl + `user/spinearn`, { headers })
        .then((res) => {
          setSpinStatus(res.data);
          setCoin(res.data.spinEarn);
          setIsDisableDaily(false);
          setWinners(res.data.winnerdata);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };

  const updateSpin = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (access_token) {
      axios
        .get(baseUrl + `user/spinUpdate`, { headers })
        .then((res) => {})
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
          }
        });
    }
  };

  useEffect(() => {
    getSpin();
    return () => {};
  }, []);

  const onFinished = () => {
    if (coin != "0") {
      notify(spinStatus.spinEarn);
    } else {
      betterLuck();
    }
    updateSpin();
  };

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <div className="leftContainer" style={{ flex: 1 }}>
        <div style={{ textAlign: "center", padding: "20px 0" }}>
          <h3>
            <strong>Spin and Earn Daily Free</strong>
          </h3>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="roulette-wrapper">
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              radius={50}
              outerBorderColor={["#f2f2f2"]}
              outerBorderWidth={[5]}
              innerBorderColor={["#f2f2f2"]}
              radiusLineColor={["#dedede"]}
              radiusLineWidth={[1]}
              fontSize={40}
              textColors={["#ffffff"]}
              backgroundColors={[
                "#F22B35",
                "#F99533",
                "#24CA69",
                "#514E50",
                "#46AEFF",
                "#9145B7",
              ]}
              onStopSpinning={() => {
                onFinished();
                setMustSpin(false);
                setIsDisableDaily(true);
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Stack buttons vertically
            justifyContent: "center",
            alignItems: "center", // Center buttons horizontally
            marginTop: "20px",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <button
              onClick={() => {
                // setspintype("daily");
                handleSpinClick();
              }}
              style={{
                backgroundColor: "#24CA69",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginBottom: "10px", // Space between buttons
              }}
              disabled={isDisableDaily}
            >
              Play Daily Free Spin
            </button>
          </div>
        </div>
        <div
          style={{
            maxWidth: "400px",
            margin: "20px auto",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#e0f7fa",
              color: "#00796b",
              marginBottom: "20px",
            }}
          >
            Last 24 Hour Winners List
          </h2>
          <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
            {winners.map((winner, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderBottom: "1px solid #b2dfdb",
                }}
              >
                <span style={{ fontSize: "16px", color: "#004d40" }}>
                  {winner.name} WON{" "}
                  <strong style={{ color: "#ff5722" }}>{winner.prize}</strong>{" "}
                  💵
                </span>
                <span style={{ fontSize: "14px", color: "#00796b" }}>
                  {winner.time}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <ToastContainer />
      </div>
      <div className="rightContainer" style={{ flex: 1 }}>
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Spinearn;
