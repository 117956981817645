// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roulette-wrapper {
  width: 150px;
  height: 150px;

  > div {
    height: 100%;
    width: 100%;
    max-height: unset;
    max-width: unset;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app2.js/uiComponents/YourComponent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;;EAEb;IACE,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,gBAAgB;EAClB;AACF","sourcesContent":[".roulette-wrapper {\r\n  width: 150px;\r\n  height: 150px;\r\n\r\n  > div {\r\n    height: 100%;\r\n    width: 100%;\r\n    max-height: unset;\r\n    max-width: unset;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
